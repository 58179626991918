<template>
    <div class="login-container">
        <div class="title-section">
            <p class="title">中铁长江交通设计集团有限公司</p>
            <p class="sub-title">
                内河船舶尾气污染物监测平台
            </p>
            <img class="login-bg"
                src="https://xkxk-1253929253.cos.ap-shanghai.myqcloud.com/vue3-ts-vite-admin/login_bg.png" alt="" />
        </div>
        <div class="login-section">
            <div class="login-title-section">
                <p class="login-title">账号登录</p>
            </div>
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="50">
                <FormItem label="账号" prop="account">
                    <Input v-model="formValidate.account" placeholder="请输入账号"></Input>
                </FormItem>
                <FormItem label="密码" prop="password">
                    <Input type="password" v-model="formValidate.password" placeholder="请输入密码"></Input>
                </FormItem>
            </Form>
            <div class="btn-section">
                <Button @click="loginHandler('formValidate')" class="btn">登录</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { loginIn } from '@/utils/api';
import { customPrint, throttle, antiShake } from '@/utils/util';

export default {
    data() {
        return {
            formValidate: {
                account: '',
                password: ''
            },
            ruleValidate: {
                account: [
                    { required: true, message: '账号不能为空', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        loginHandler(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loginHandler();
                } else {
                    this.$Message.error('账号或密码错误');
                }
            })
        },
        loginHandler () {
            let params = {
                password: this.formValidate.password,
                userName: this.formValidate.account
            }
            loginIn(params).then(res => {
                customPrint('登录回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    let userInfo = res.data;
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    this.$router.push('/video');
                    this.$Message.success('登录成功');
                } else {
                    this.$Message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    // background-image: url('/assets/login_bg.png');
    // background-size: 100%;
    background: url("~@/assets/login_bg.png") no-repeat center center;
    background-size: 100% 100%;


    .title-section {
        margin-top: 100px;
        padding-left: 200px;

        .title {
            font-size: 35px;
            margin-top: 20px;
            font-weight: 600;
            color: #45a1f1;
            // background-image: gradient(linear, 0 0, 0 bottom, from(#fff), to(#45a1f1));
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        .sub-title {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 50px;
            color: #45a1f1;
            // background-image: gradient(linear, 0 0, 0 bottom, from(#fff), to(#45a1f1));
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
        }


        .desc {
            color: #e1ebec;
            font-size: 16px;
            line-height: 30px;
            width: 600px;
        }

        .login-bg {
            width: 600px;
            height: 450px;
            border-radius: 10px;
        }
    }

    .footer-section {
        text-align: center;
        padding-top: 10px;

        .version {
            font-size: 13px;
            color: #a4a4a4;
        }
    }

    .login-section {
        width: 450px;
        background-color: #fff;
        border-radius: 5px;
        position: absolute;
        right: 250px;
        top: 250px;
        padding: 0 30px;
        padding-bottom: 30px;

        .input-section {
            padding: 20px 90px;
        }

        .login-title-section {
            text-align: center;
            padding: 20px 0;

            .login-title {
                font-size: 18px;
            }
        }

        .btn-section {
            margin-top: 40px;

            .btn {
                width: 100%;
                background-color: #45a1f1;
                color: #fff;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                padding: 0;
                margin: 0;
                border: none;
            }
        }
    }
}
</style>
